import React, { useState } from 'react';
import Papa from 'papaparse';
import './OneD.css';
import './csvTable.css';
import { JsonViewer } from '@textea/json-viewer';
import { LazyTable } from '../Components/LazyTable';
import { downloadJSON } from '../utills/downloadCsv';

function CsvToJSON() {
    const [csvData, setCsvData] = useState([]);
    const [keyPosition, setKeyPosition] = useState('row');
    const [jasonRes, setJsonRes] = useState(1);
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            Papa.parse(file, {
                header: true,
                complete: (results) => {
                    setCsvData(results.data);
                },
                skipEmptyLines: true,
            });
        }
    };

    const handleKeyPositionChange = (event) => {
        setKeyPosition(event.target.value);
    };

    const convertToJSON = () => {
        let jsonResult;

        if (keyPosition === 'row') {
            jsonResult = csvData.map(row => ({
                ...row
            }));
        } else {
            const headers = Object.keys(csvData[0] || {});
            jsonResult = headers.map(() => ({}))
            csvData.forEach(row => {
                if (headers.length) {
                    const mainKey = headers[0]
                    headers.forEach((head, i) => {
                        jsonResult[i][row[mainKey]] = row[head]
                    })
                }
                console.log(Object.keys(csvData[0] || {}))
            })
        }
        setJsonRes(jsonResult)
    };

    const renderTable = () => {
        const headers = Object.keys(csvData[0] || {});
        return (
            <div>
                RowsCount = {csvData.length}<br />
                ColumnCount = {headers.length}
                {csvData.length ? <LazyTable data={csvData} />:null}
            </div>
        );
    };

    return (
        <div className="one-d-container">
            
            <h1>Upload CSV and Convert to JSON</h1>
            <div className="input-section">
                <input
                    type="file"
                    accept=".csv"
                    className="file-input"
                    onChange={handleFileChange}
                />
            </div>
            <div className="select-section">
                <label htmlFor="key-position">Main keys of JSON are in:</label>
                <select
                    id="key-position"
                    className="key-position-select"
                    value={keyPosition}
                    onChange={handleKeyPositionChange}
                >
                    <option value="row">Row</option>
                    <option value="column">Column</option>
                </select>
            </div>

            {renderTable()}
            <button className="convert-button csv-to-json-btn" onClick={convertToJSON}  >Convert to JSON</button>

            <div className="output-section">
                <h2>JSON Output</h2>
                <button id='download-btn' onClick={() => downloadJSON(csvData)} >Download Json</button>
                <JsonViewer theme={'dark'} value={jasonRes} />
            </div>
        </div>
    );
}

export default CsvToJSON;
