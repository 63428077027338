import { Route, Routes } from "react-router-dom"
import App from "./App"
import CsvToJSON from "./csvToJSON"
const RoutePage = () => {
    return (
        <Routes>
            <Route path='/' element={<App />}></Route>
            <Route path='/csvJson' element={<CsvToJSON />}/>
        </Routes>
    )
}

export default RoutePage