import { useNavigate } from 'react-router-dom';
import './App.css';
import { useEffect } from 'react';

function App() {
  const navigate =useNavigate()
  const redirect =() => navigate('/csvjson',{
    replace:true,
    redirect:true
  })
  useEffect(() => {
    redirect()
  },[])
  return (
    <div className="App">
     <button className='csv-to-json-btn'  onClick={()=>navigate('/csvjson')}>Csv to json</button>
    </div>
  );
}

export default App;
