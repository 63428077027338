export function downloadJSON(data, filename = 'data.json') {
    // Convert the JSON object to a string
    const jsonString = JSON.stringify(data, null, 2); // Pretty-print with 2 spaces indentation
    
    // Create a Blob from the JSON string
    const blob = new Blob([jsonString], { type: 'application/json' });
    
    // Create a link element
    const link = document.createElement('a');
    
    // Set the URL of the Blob to the link's href
    link.href = URL.createObjectURL(blob);
    
    // Set the download attribute with a filename
    link.download = filename;
    
    // Append the link to the document body
    document.body.appendChild(link);
    
    // Programmatically click the link to trigger the download
    link.click();
    
    // Clean up and remove the link element
    document.body.removeChild(link);
}
