import { useState, useRef, useEffect, useCallback } from "react";

export const LazyTable = ({ data }) => {
    const [visibleData, setVisibleData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [headers, setHeaders] = useState([]);
    const rowsPerPage = 50;
    const containerRef = useRef(null);
    const timer = useRef(null);

    // Load more data function
    const loadMoreData = useCallback(() => {
        if (loading || visibleData.length >= data.length) return;

        setLoading(true);

        if (timer.current) {
            clearTimeout(timer.current);
        }

        timer.current = setTimeout(() => {
            setVisibleData(prevData => {
                const nextPage = currentPage + 1;
                const end = nextPage * rowsPerPage;

                setCurrentPage(nextPage);
                return [...prevData, ...data.slice(prevData.length, end)];
            });

            setLoading(false);
        }, 1); // Simulate network delay
    }, [loading, currentPage, data]);

    // Scroll event handler
    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
            if (scrollHeight - scrollTop - clientHeight < 10) {
                loadMoreData();
            }
        }
    }, [loadMoreData]);

    // Set up and clean up event listener
    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            console.log("Adding scroll event listener.");
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                console.log("Removing scroll event listener.");
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);

    // Set initial visible data and headers
    useEffect(() => {
        setVisibleData(data.slice(0, rowsPerPage));
        setHeaders(Object.keys(data[0] || {}));
    }, [data]);

    return (
        <div>   
            <div className="csv-table-container" ref={containerRef} style={{ height: '400px', overflowY: 'auto' }}>
                <table className="csv-table">
                    <thead>
                        <tr>
                            {headers.map(header => (
                                <th key={header}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {visibleData.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {headers.map(header => (
                                    <td key={header}>{row[header]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                {loading && <div className="loading">Loading more data...</div>}
            </div>
        </div>
    );
};
